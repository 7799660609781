import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ClientAgentNomination = () => {
  return (
    <Layout>
      <Seo
        title="User Guide How to Do Client Agent Nomination"
        description={`Learn how to nominate an agent for your business with our guide, covering digital identity setup and using the Australian Government's online systems.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`client-agent-nomination`} />
    </Layout>
  )
}

export default ClientAgentNomination
